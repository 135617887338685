import '../App.css';
import { Container, Button, Navbar, Nav, NavItem, NavDropdown, MenuItem, NavLink,FormControl ,Form, FormLabel, Table} from 'react-bootstrap';
import React, { useState, Component } from 'react';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import useBackend, {CallAPI, Loading} from '../services/useBackend';
import {useParams, useNavigate} from 'react-router-dom';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions} from '../services/misc';


var typingTimeout = null;
var customerGUID = null;

function handleChange(event){
	clearTimeout(typingTimeout);
	typingTimeout = setTimeout(handleSave, 475);
}

function handleSave(){
	const ProductForm = document.getElementById('ProductForm');
	const formData = new FormData(ProductForm);

	const formDataObj = Object.fromEntries(formData.entries());
	formDataObj.Banned = formData.has('Banned') ? true : false;

	CallAPI('/api/admin/customer/'+customerGUID,"PATCH",JSON.stringify(formDataObj));
}

export default function CustomerEdit() {
    const {CustomerGUID} = useParams();
	customerGUID = CustomerGUID;
    const {loading, data} =  useBackend('/api/admin/customer/'+CustomerGUID);

	const navigate = useNavigate();

    const orderClick = (event)  =>  {
        const orderguid = event.currentTarget.getAttribute("orderguid");
        navigate("/orders/"+orderguid);
    } 	

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida>
						<Rubrik>Kundinfo</Rubrik>

						<Form onSubmit={handleSave} id="ProductForm">

							<Form.Group className="mb-3" >
								<Form.Label>Namn (<small>{data.Customer.SSN}</small>)</Form.Label>
								<Form.Control type="text" name="Name" placeholder="Namn"  defaultValue={data.Customer.Name} onChange={handleChange} />
							</Form.Group>

							<Form.Group className="mb-3" >
								<Form.Label>Email</Form.Label>								
								<Form.Control type="text" name="eMail" placeholder="Email" defaultValue={data.Customer.eMail} onChange={handleChange} />
							</Form.Group>  							
						
							<Form.Group className="mb-3" >
								<Form.Check type="switch" name="Banned" id="Banned" label="Spärra användare" defaultChecked={data.Customer.Banned} onChange={handleChange} />
							</Form.Group>
						</Form> 	

						<Rubrik>Köp</Rubrik>

							<Table responsive="sm">
								<thead>
									<tr><th>Ordernr.</th><th className="d-lg-block d-none">Skapad</th>
									<th>Status</th><th>Betalmetod</th><th style={{textAlign:'right'}}>TotalAmount</th><th style={{textAlign:'right'}}>TotalVat</th></tr>
								</thead>
								<tbody>
									{data.Customer.Orders.map((item) => {
										return <tr key={item.Guid}>
												<td><span style={{padding:5}} className='sub_' orderguid={item.Guid} onClick={orderClick} title="Detaljer">{item.SerialNumber}</span></td>
												<td><span style={{padding:5}} className='sub_' orderguid={item.Guid} onClick={orderClick} title="Detaljer">{DateTimeToLocal(item.Created)}</span></td>
												
												<td>{item.StateText}</td>
												<td>{item.PaymentMethodTx}</td>
												<td style={{textAlign:'right'}}>{item.TotalAmount}</td>
												<td style={{textAlign:'right'}}>{item.TotalVat}</td>
											  </tr>
									})}
								</tbody>
							</Table>
                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (<Loading />);        
    }
}