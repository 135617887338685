import { Container, Button,Alert,Table, Modal, Col, Row } from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,Stycke,StyckeCompact} from "./ContentStyles";

import { FaFilePdf, FaReceipt, FaEnvelope,FaBars, FaCog } from 'react-icons/fa';
import useBackend, {CallAPI, CallAPIGet, Loading, SpinnerX, IsLoggedIn} from '../services/useBackend';
import React, {useMemo, useState, useEffect } from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import MaterialReactTable from 'material-react-table';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions, AddTimezoneOffset} from '../services/misc';

export default function MyComponent() {
    const {loading, data, Refresh} =  useBackend('/api/admin/shops');

    var {loggedIn, isAdmin, isSuperUser, userName, accountIsLocked} = IsLoggedIn();

    const [modalshow,  setModalshow] = useState(false);
    const initModal = () => { return setModalshow(!modalshow) } 

    const [selectedShopData, setSelectedShopData]   = useState({Name:"...", OpeningHoursUTCStart:"00:00:00", OpeningHoursUTCEnd:"00:00:00", EntryCode:{Code:""}});
    const [selectedShopState, setSelectedShopState] = useState({ConnectionState:"Ej tillgänglig",ConnectionStateColor:"#F00", DoorState:"Ej tillgänglig", Temperature:"Ej tillgänglig"});  

    const navigate = useNavigate();

    if (accountIsLocked) navigate("/instance/invoices");

    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 });

    useEffect(() => { navigate('?pageindex='+pagination.pageIndex+'&pagesize='+pagination.pageSize) }, [pagination]);

    const detailShopClick = async (event, rowData)  =>  {

        setSelectedShopData(rowData.original)
        setModalshow(!modalshow);
        var LockState = {ConnectionState:"Hämtar info", DoorState:"Ej tillgänglig", Temperature:"Ej tillgänglig"};
        
        console.log(rowData.original.Guid);
        let {resultVariant, resultVisible, resultText, jsondata} = await CallAPIGet('/api/admin/shop/'+rowData.original.Guid+'/GetState',"GET");

        if (jsondata.LockState.online) {
            LockState.ConnectionStateColor = "#24a33d";
            LockState.ConnectionState ="Online";
            LockState.DoorState =jsondata.LockState.lockState;
        } else {
            LockState.ConnectionStateColor = "#F00";
            LockState.ConnectionState ="Offline";
            LockState.DoorState ="Ej tillgänglig";
        }

        
        setSelectedShopState(LockState);
    }

    const editShopClick = (event)  =>  {
        const ShopGUID = event.currentTarget.getAttribute("shopguid");
        event.preventDefault();
        navigate("/shop/"+ShopGUID);
    }

    const addShopHandler = (event)  =>  {
        event.preventDefault();
        navigate("/shop/add");
    }    
    
    const columns = useMemo(() => [
        { size: 20, accessorKey: 'Name',        header: '#', enableColumnActions: false, enableHiding:false },
        { size: 100, accessorKey: 'Description', header: 'Description' },
        { size: 100, accessorKey: 'Company',     header: 'Company', headerClassName: 'test_'},
        
        // {  size: 50, header: 'Editera', columnDefType: 'display', enableColumnActions: false,            
        //     Cell: ({ row }) => ( <span style={{padding:5}} className='sub_' shopguid={row.original.Guid} onClick={((e) => detailShopClick(e, row.original))} title="Info "><FaReceipt /></span> ),
        // },                   
      ]
    );  

    const initialState = 
    { 
        density: 'compact',
        columnVisibility: { Company: false, Description:true }, 
        pagination : pagination 
    };  

    const state = { pagination : pagination };      

	async function unLockShop(event){
        const ShopGUID = event.currentTarget.getAttribute("shopguid");
		let {resultVariant, resultVisible, resultText} = await CallAPI('/api/admin/shop/'+ShopGUID+'/unlock',"PUT");	
	
		await setTimeout(5000);
	}

    function openPriceList(event){
        const ShopGUID = event.currentTarget.getAttribute("shopguid");
        window.open('https://www.nookshop.se/api/info/'+ShopGUID+'/Pricelist', '_blank', 'noreferrer');
    }    

    if (!loading) {
        return (
            <ContentWrapper>

                <Modal show={modalshow} onHide={initModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedShopData.Description} ({selectedShopData.Name})</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

        
                        <Row>
                            <Col>Företag</Col><Col>{selectedShopData.Company}</Col>                        
                        </Row>
                        <Row>
                            <Col>Minsta ålder</Col><Col>{selectedShopData.MinCustomerAge}</Col>                        
                        </Row>
                        <Row>
                            <Col>Öppettider</Col><Col>{SplitoutLocalTimeFromISO(selectedShopData.OpeningHoursUTCStart)}&nbsp; till &nbsp;{SplitoutLocalTimeFromISO(selectedShopData.OpeningHoursUTCEnd)}</Col>                        
                        </Row>

                        <Row>
                            <Col>Uppkoppling</Col><Col><font color={selectedShopState.ConnectionStateColor}><b>{selectedShopState.ConnectionState}</b></font></Col>                        
                        </Row>

                        <Row>
                            <Col>Dörr</Col><Col>{selectedShopState.DoorState}</Col>                        
                        </Row>

                        <Row>
                            <Col>Temperatur</Col><Col>{selectedShopState.Temperature}</Col>                        
                        </Row>                          

                        <Row>
                            <Col>EntryCode</Col><Col>{selectedShopData.EntryCode.Code}</Col>                        
                        </Row>                        
                  
                    {/* EntryCode: {selectedShopData.EntryCode.Code}<br /> */}
                  

                  </Modal.Body>
                  <Modal.Footer>
                  <Button className="QmeButton_" variant="secondary" type="button" shopguid={selectedShopData.Guid} onClick={editShopClick}><FaCog/></Button> &nbsp;&nbsp;   
                    <Button className="QmeButton" variant="primary" type="button" shopguid={selectedShopData.Guid} onClick={openPriceList}>Hämta prislista</Button> &nbsp;&nbsp;   
                    <Button className="QmeButton" variant="primary" type="button" shopguid={selectedShopData.Guid} onClick={unLockShop}>Lås upp</Button>&nbsp;&nbsp;
                    {/* <Button variant="danger" onClick={initModal}>Close</Button> */}
                  </Modal.Footer>
                </Modal>

                <Container>
                <Sida>                    
                        <MaterialReactTable  columns={columns} data={data.Shops} 
                        muiTablePaginationProps={{ showFirstButton: false, showLastButton: false ,labelRowsPerPage:"Rader", rowsPerPageOptions: [25, 50, 100]}}
                        renderTopToolbarCustomActions={() => (<Rubrik>Butiker</Rubrik>)} initialState={initialState} onPaginationChange={setPagination} state={state} 
                            muiTablePaperProps={{elevation: 0, sx: {borderRadius: '0',border: '0px dashed #e0e0e0'} }} 
                            
                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                    detailShopClick(event, row);
                                },
                                sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                              })}

                        />                    
                        
                        <Button className="QmeButton" variant="primary" type="submit" onClick={addShopHandler}>Lägg till butik</Button>&nbsp;&nbsp;
                    </Sida>
                </Container>
            </ContentWrapper>            
        );
    } else {
        return (<Loading />);         
    }
}