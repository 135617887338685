import { useState, useEffect, useRef } from 'react';
import ClipLoader from "react-spinners/PacmanLoader";
import { Container } from 'react-bootstrap';
import { Sida, Rubrik, ContentWrapper} from "../pages/ContentStyles";
import { FaFilePdf, FaBars, FaRegCheckCircle } from 'react-icons/fa';

export default function useGetBackend(url) {
    const [data, setData] = useState([]);
    const [loading, setLoadning] = useState(true);

    const [resultVariant, setResultVariant] = useState(null);
    const [resultText,    setResultText]    = useState(null);
    const [resultVisible, setResultVisible] = useState(null);
    const [resultSuccess, setResultSuccess] = useState(true);

    var queryParam = useRef("");

    function Refresh(pageIndex=0, pageSize=25) {
        console.log("pageIndex:"+pageIndex);
        console.log("pageSize:"+pageSize);
        init(pageIndex, pageSize);
    }

    function Seek(QueryParam) {
        queryParam.current = QueryParam;
        init();
    }

    function Clear() {
        setData(null);
    }

    async function init(pageIndex=0, pageSize=25) {
        //setLoadning(true);
        try {
            // const response = await fetch(url + queryParam.current, {
            var query = queryParam.current;
            if (query.includes('?') || url.includes('?')) {
                query=query+"&"+ "pageSize="+pageSize+"&pageIndex="+pageIndex;
            } else {
                query="?pageSize="+pageSize+"&pageIndex="+pageIndex;
            }

            const response = await fetch(url + query, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accessToken':localStorage.getItem('access_token'),
                },
            });

            if (response.status === 401) {
                window.location.href = "/login";                    
            }

            if (response.ok) {
                const json = await response.json();
                setResultVariant('success');
                setResultVisible(false);
                setResultText('');
                setData(json);
            } else {
                setResultVariant('warning');
                setResultVisible(true);
                setResultText('Ett fel uppstod!!');

                throw response;
            }
            
        }
        catch (e) {
            setResultVariant('warning');
            setResultVisible(true);
            setResultText('Ett fel uppstod!!!');
            setResultSuccess(false);
        }
        finally {
            setLoadning(false);
        }
    }

    useEffect(() => {init()}, [url]); 

    return { resultVariant, resultVisible, resultText, resultSuccess, loading, data, Refresh, Seek, Clear }; 
}

export async function CallDownload(url) {
    var filedata;
    try {
        const response =  await fetch(url, {
            method: "GET",
            headers: {
                'accessToken':localStorage.getItem('access_token'),
            }
        });

        if (response.ok) {
            const content = await response.blob();
            console.log(content.size);
            filedata=content;
        }
    }
    catch (e) {
    }
    finally {

    }
    return filedata; 
}

export async function CallAPIGet(url) {
    var resultVariant, resultVisible, resultText="...";
    var jsondata;
    try {
        const response =  await fetch(url, {
            method: "GET",
            headers: {
                'accessToken':localStorage.getItem('access_token'),
            }
        });

        if (response.ok) {
            jsondata =  await response.json();
            
            resultVisible=true;
            resultText=jsondata.resultText;

            if (jsondata.errorsException) {
                resultVariant ="warning";    
            } else {
                resultVariant ="success";
            }
        } else {
            resultVariant ="warning";
            resultVisible=true;
            resultText= 'Ett fel uppstod!';
        }
    }
    catch (e) {
        resultVariant ="warning";
        resultVisible=true;
        resultText= 'Ett fel uppstod!!';
    }
    finally {

    }
    return { resultVariant, resultVisible, resultText, jsondata }; 
}

export async function CallAPI(url, method = 'GET', body = {}) {
    var resultVariant, resultVisible, resultText="...";
    var jsondata;
    try {
        
        const response =  await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'accessToken':localStorage.getItem('access_token'),
            },            
            body: body
        });

        if (response.ok) {
            jsondata =  await response.json();
            
            resultVisible=true;
            
            resultText=jsondata.resultText;
            if (resultText=="") resultVisible = false;

            if (jsondata.errorsException) {
                resultVariant ="warning";    
            } else {
                resultVariant ="success";
            }

        } else {
            resultVariant ="warning";
            resultVisible=true;
            resultText= 'Ett fel uppstod!';
        }
    }
    catch (e) {
        resultVariant ="warning";
        resultVisible=true;
        resultText= 'Ett fel uppstod!!';
    }
    finally {

    }
    return { resultVariant, resultVisible, resultText, jsondata }; 
}

export function IsLoggedIn() {    
    const [loggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const [isSuperUser, setIsSuperUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [accountIsLocked, setAccountIsLocked] = useState(false);

    useEffect(() => {
        
        async function init() {
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'accessToken':localStorage.getItem('access_token'),
                    },
                });

                if (response.ok) {
                    const json =  await response.json();
                    setUserName(json.userName)
                    setLoggedIn(true);

                    setIsSuperUser(json.isSuperUser);
                    setIsAdmin(json.isAdmin);
                    setAccountIsLocked(json.accountIsLocked)
                } else {
                    setLoggedIn(false);
                }
            }
            catch (e) {
                console.log("IsLoggedIn: error");                
                setLoggedIn(false);
            }
        }

        var token = localStorage.getItem('access_token');
        var url = "/backend/auth/token/"+token+"/isValid";
        if (token!=="token" && token !== "" && token !== null) {            
            init();
        }
    });  

    return { loggedIn, userName, isAdmin, isSuperUser, accountIsLocked };     
}

export function XLogOff() {
    localStorage.setItem('access_token', "");
    //localStorage.setItem('isLoggedIn', true);
    window.location.href = "/login";
}

export function Loading() {

    let [loadingText, setLoadingText] = useState("Laddar");
    let [color, setColor] = useState(process.env.REACT_APP_BrandColor);

    return (
      <ContentWrapper>
          <Container>
              <Sida >
              <Rubrik>{loadingText}</Rubrik>
              <div className="Spinner">
                <ClipLoader color={process.env.REACT_APP_BrandColor}  size={30} /><br></br>
              </div>
          </Sida>
          </Container>
      </ContentWrapper>
    );  
};

export function SpinnerX() {
    let [color, setColor] = useState(process.env.REACT_APP_BrandColor);
    
      return (
      <div className="Spinner">
      <ClipLoader color={process.env.REACT_APP_BrandColor}  size={30} /><br></br>
      </div>
      )
    };

    export function Check(props) {
        console.log(props.checked);
        if (props.checked) {
          return <FaRegCheckCircle />;
        } else {
          return "";
        }
      }    

      export function CheckInvoice(props) {
        console.log(props.checked);
        if (props.checked) {
          return <FaRegCheckCircle />;
        } else {
          return <p  style={{color:"red"}}>Obetald</p>;
        }
      }         